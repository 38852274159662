import { createDateTime } from "ndr-designsystem";
import { PlanningData } from "../../fixed/PlanningData/PlanningData";
import SentToConnectPlusStatus from "../../fixed/PlanningData/SentToConnectPlusStatus";

export interface PlanningDataDto {
    internalID: string;
    date?: string;
    createdDateTime?: string;
    numberOfTimeSeries: number;
    dispatches: { dispatchId: string; dispatchedAt: string }[];
    sentToConnectPlusStatus: SentToConnectPlusStatus;
}

export const fromDto = (dto: PlanningDataDto): PlanningData => ({
    ...dto,
    date: dto.date ? createDateTime(dto.date) : undefined,
});
