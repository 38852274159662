import { createAsyncThunk } from "@reduxjs/toolkit";
import { orderBy } from "lodash";
import Api from "../../../../api/Api";
import { RootState } from "../../../../app/store";
import { convertBusinessTypeAndDirection, removeNulls, TimeseriesPageSize } from "../../../../utils";
import { PlanningDataDto } from "../../../../api/dtos/PlanningData/PlanningDataDto";
import { PlanningDataDetailsDto } from "../../../../api/dtos/PlanningData/PlanningDataDetailsDto";
import {
    selectFirstTimeSeriesInfo,
    setAllPlanningData,
    setCurrentPlanningData, setIsNextFetchingPossible, setIsPreviousFetchingPossible,
    setSendingStatusPlanningData
} from "./store";
import { fetchAllControlGroupsAndResources } from "../../../appStateSlice";
import { fetchControllableResourceByIdAction } from "../../../controllableResources/store/thunks";
import { setCurrentProductionData } from "../../productionData/store/slice";
import { PlannedResourceTimeSeriesDto } from "../../../../api/dtos/PlanningData/PlannedResourceTimeSeriesDto";

export const fetchPlanningDataByResource = createAsyncThunk(
    "planningData/fetchByControllableResourceId",
    async ({ id, take }: { id: string, take: number}, { getState, dispatch }) => {
        let response: PlanningDataDto[] | undefined = [];
        let lastId: string | undefined;
        const state = getState() as RootState;
        const previousPlanningData = state.planningData.allPlanningData ?? []

        if (state.planningData && state.planningData.allPlanningData) {
            lastId = state.planningData.allPlanningData[state.planningData.allPlanningData.length - 1].internalID;
        }

        response = (await Api.fetchPlanningDataByControllableResourceId(id, lastId, take)).data ?? [];
        response = orderBy(response.map(data => removeNulls(data)), ['date'], ['desc']);

        if (response.length < take) dispatch(setIsNextFetchingPossible(false))
        if (lastId === undefined) dispatch(setIsPreviousFetchingPossible(false));

        dispatch(setAllPlanningData([...previousPlanningData, ...response]));
        return response;
    }
)

export const fetchPreviousPlanningData = createAsyncThunk(
    "planningData/fetchPreviousPlanningData",
    async ({ CRID, id, take }: {CRID: string, id: string, take: number}, { getState, dispatch }) => {
        const state = getState() as RootState;
        const { isPreviousFetchingPossible, allPlanningData } = state.planningData
        if (!isPreviousFetchingPossible) return [];

        let previousPlanningData = (await Api.fetchPreviousPlanningData(CRID, id, take)).data;
        previousPlanningData = orderBy(previousPlanningData.map(data => removeNulls(data)), ['date'], ['desc']);
        if (previousPlanningData.length < take) dispatch(setIsPreviousFetchingPossible(false))
        dispatch(setAllPlanningData([...previousPlanningData, ...allPlanningData ?? []]));
        return previousPlanningData;
    }
)

export const fetchPlanningDataDetailsById = createAsyncThunk(
    "planningData/fetchById",
    async ({ CRID, id, force }: { CRID: string, id: string, force: boolean }, { getState, dispatch }) => {
        let response: PlanningDataDetailsDto | undefined;
        const state = getState() as RootState;

        if (!force && state.planningData.currentPlanningData && id === state.planningData.currentPlanningData.internalID) {
            response = state.planningData.currentPlanningData;
        } else {
            response = removeNulls((await Api.fetchPlanningDataDetails(CRID, id)).data);
            const ordered: PlannedResourceTimeSeriesDto[] = [];
            const prodTimeSeries = response?.timeSeries.find(ts => convertBusinessTypeAndDirection(ts.businessType, ts.direction) === "PROD");
            const PMinTimeSeries = response?.timeSeries.find(ts => convertBusinessTypeAndDirection(ts.businessType, ts.direction) === "Pmin")
            const PMaxTimeSeries = response?.timeSeries.find(ts => convertBusinessTypeAndDirection(ts.businessType, ts.direction) === "Pmax")

            if (prodTimeSeries && response?.timeSeries) {
                ordered.push(prodTimeSeries)
                response.timeSeries = response?.timeSeries.filter(ts => ts.ExternalID !== prodTimeSeries.ExternalID)
            }
            if (PMinTimeSeries && response?.timeSeries) {
                ordered.push(PMinTimeSeries)
                response.timeSeries = response?.timeSeries.filter(ts => ts.ExternalID !== PMinTimeSeries.ExternalID)
            }
            if (PMaxTimeSeries && response?.timeSeries) {
                ordered.push(PMaxTimeSeries)
                response.timeSeries = response?.timeSeries.filter(ts => ts.ExternalID !== PMaxTimeSeries.ExternalID)
            }
            ordered.push(...orderBy(response?.timeSeries ?? [], it => convertBusinessTypeAndDirection(it.businessType, it.direction), ['desc']))
            if (response?.timeSeries) response.timeSeries = ordered;
        }

        dispatch(setCurrentPlanningData(response))
        return response;
    }
)

export const resetPlanningDataState = createAsyncThunk(
    "planningData/resetState",
    async (_: never, { dispatch }) => {
        await dispatch(setCurrentPlanningData(undefined))
        await dispatch(selectFirstTimeSeriesInfo())
        await dispatch(setSendingStatusPlanningData())
        await dispatch(setCurrentProductionData(undefined));
        await dispatch(setIsPreviousFetchingPossible(true));
        await dispatch(setIsNextFetchingPossible(true));
    })

export const buildPlanningDataState = createAsyncThunk(
    "planningData/buildState",
    async ({
               controllableId,
               seriesId,
               force,
               take
           }: { controllableId: string, seriesId: string, force: boolean, take: number }, { dispatch, getState }) => {
        await dispatch(fetchAllControlGroupsAndResources({ force, fetchResources: true, fetchGroups: false }))

        const controllableResource = await dispatch(fetchControllableResourceByIdAction({
            id: controllableId,
            force,
            setAsCurrent: true
        })).unwrap();

        if (controllableResource && controllableResource.inventoryItemId) {
            await dispatch(fetchPlanningDataDetailsById({
                CRID: controllableResource.inventoryItemId,
                id: seriesId,
                force
            }));
            const state = getState() as RootState;
            if (state.planningData.allPlanningData != null && state.planningData.allPlanningData.length > 0) {
                const currentItemIndex = state.planningData.allPlanningData?.findIndex(pd => pd.internalID === seriesId);
                if (currentItemIndex === -1 || currentItemIndex == null) return;
                if (state.planningData.allPlanningData.length - currentItemIndex <= TimeseriesPageSize && state.planningData.isNextFetchingPossible) {
                    await dispatch(fetchPlanningDataByResource({ id: controllableResource.inventoryItemId, take: TimeseriesPageSize * 50 }))
                }
                if (currentItemIndex < TimeseriesPageSize && state.planningData.isPreviousFetchingPossible) {
                    await dispatch(fetchPreviousPlanningData({ CRID: controllableResource.inventoryItemId, id: seriesId, take: TimeseriesPageSize * 50 }))
                }
            } else {
                await dispatch(fetchPreviousPlanningData({ id: seriesId, CRID: controllableResource.inventoryItemId, take }));
                await dispatch(fetchPlanningDataByResource({ id: controllableResource.inventoryItemId, take }));
            }
        }
    }
)

